import { flatten } from '@angular/compiler';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { first } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { ConfirmDialogService } from 'src/app/components/confirm-dialog/confirm-dialog.service';
import { MissionEditor } from 'src/app/components/mission-editor/mission-editor.service';
import { MissionWithKey } from 'src/app/models/keyed';
import { MissionService } from 'src/app/services/mission.service';
import { MuxerService, VideoSourceModel } from 'src/app/services/muxer.service';
import { ProfileService } from 'src/app/services/profile.service';
import { StateService } from 'src/app/services/state.service';
import { VideoPlayerService } from 'src/app/services/video-player.service';
import { isNotNil } from 'src/utils/is-not-nil';
import { Destroyable, MixinRoot } from 'src/utils/mixins';

@Component({
  selector: 'mk-mission-toolbar',
  templateUrl: './mission-toolbar.component.html',
  styleUrls: ['./mission-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionToolbarComponent extends Destroyable(MixinRoot) implements OnInit {
  private currentMission$: Observable<MissionWithKey>;

  missionTitle$: Observable<string>;
  isMissionActive$: Observable<boolean>;

  videoSources$: Observable<VideoSourceModel[]>;
  currentSource$: Observable<VideoSourceModel | null>;

  isAdminOrOwner$: Observable<boolean>;
  isUsingWebRtc$ = this.playerService.isUsingWebRtc$;
  isLiveStream$ = this.playerService.isLiveStream$;

  sourceTabClicked = new Subject<number>();
  editButtonClicked = new Subject<void>();
  refreshButtonClicked = new Subject<void>();
  removeStreamClick$ = new Subject<string>();
  missionActiveInactiveClicked = new Subject<boolean>();

  constructor(
    private missionService: MissionService,
    private muxerService: MuxerService,
    private state: StateService,
    private missionEditor: MissionEditor,
    private profile: ProfileService,
    private confirm: ConfirmDialogService,
    private toastr: NbToastrService,
    private playerService: VideoPlayerService,
    private ref: ChangeDetectorRef
  ) {
    super();

    this.currentMission$ = this.state.getCurrentMission().pipe(share());

    this.isMissionActive$ = this.state.isCurrentMissionActive.pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.missionTitle$ = combineLatest([
      this.currentMission$.pipe(
        map((mission) => mission.title),
        distinctUntilChanged()
      ),
      this.isMissionActive$.pipe(
        map((isActive) => (isActive ? 'Live' : 'Not Running')),
        distinctUntilChanged()
      ),
    ]).pipe(map(([title, status]) => `${title} • ${status}`));

    this.videoSources$ = this.state.getCurrentMisssionVideoSources().pipe(
      map((sources) => sources.filter((source) => source.isActive || source.type === 'box')),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.currentSource$ = this.state.getCurrentVideoSource();

    this.isAdminOrOwner$ = combineLatest([
      this.profile.isAdmin,
      this.profile.isSuperUser,
      this.currentMission$,
      this.profile.user,
    ]).pipe(
      map(
        ([isAdmin, isSuperUser, mission, user]) =>
          isAdmin || isSuperUser || mission.owner === user?.uid
      ),
      takeUntil(this.destroyed$),
      share()
    );

    this.isLiveStream$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.ref.detectChanges();
    });
  }

  ngOnInit(): void {
    // Select the first stream when changing mission
    this.state
      .getCurrentMissionId()
      .pipe(
        switchMapTo(
          this.state.getCurrentMisssionVideoSources().pipe(
            map((sources) => {
              const streams = flatten(sources.map((source) => source.streams || []));
              return streams.find((stream) => stream.isActive) || first(streams);
            }),
            take(1)
          )
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((stream) => {
        this.state.setVideoStream(stream || null);
      });

    // Set current stream on tab click
    this.sourceTabClicked
      .pipe(
        withLatestFrom(this.videoSources$, this.currentSource$),
        map(([tabIndex, sources, currentSource]) => ({
          newSource: sources[tabIndex],
          currentSource,
        })),
        filter(
          ({ newSource, currentSource }) =>
            isNotNil(newSource) &&
            (!currentSource ||
              newSource.title !== currentSource.title ||
              newSource.device !== currentSource.device)
        ),
        map(({ newSource }) => newSource),
        takeUntil(this.destroyed$)
      )
      .subscribe((source) => {
        this.state.setVideoStream(first(source.streams) || null);
        this.missionService.liveStreamEventChange$.next();
        this.isUsingWebRtc$.next(true);
      });

    // Set mission state on start/stop click
    this.missionActiveInactiveClicked
      .pipe(withLatestFrom(this.currentMission$), takeUntil(this.destroyed$))
      .subscribe(([activeInactive, mission]) =>
        this.missionService.activateMission(mission.key, activeInactive)
      );

    // Open edit mission dialog on edit click
    this.editButtonClicked
      .pipe(withLatestFrom(this.currentMission$), takeUntil(this.destroyed$))
      .subscribe(([_, mission]) => this.missionEditor.openEditMissionDialog(mission));

    // Request refresh on refresh click
    this.refreshButtonClicked
      .pipe(withLatestFrom(this.currentMission$), takeUntil(this.destroyed$))
      .subscribe(([_, mission]) => {
        this.state.hardRefresh();
      });

    // Remove stream on Remove click
    this.removeStreamClick$
      .pipe(
        switchMap((streamId) =>
          this.confirm
            .prompt('Delete mission streams?', `This will delete mission streams`, 'Delete')
            .pipe(map((confirmed) => ({ confirmed, streamId })))
        ),
        filter(({ confirmed }) => confirmed),
        withLatestFrom(this.currentMission$),
        switchMap(([{ streamId }, mission]) =>
          this.muxerService.removeStream(mission.key, streamId)
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => this.toastr.success(null, 'Success'),
        (err: any) =>
          this.toastr.danger(
            err?.error ?? err?.message ?? err?.toString() ?? JSON.stringify(err),
            'Error'
          )
      );
  }
}
