<nb-card>
  <nb-card-header>
    <span *ngIf="missionTitle$ | async as title" [nbTooltip]="title" nbTooltipStatus="control">{{
      title
    }}</span>
    <div class="flex-fill"></div>
    <nb-actions size="small">
      <nb-action
        *ngIf="(isLiveStream$ | async) && (isUsingWebRtc$ | async)"
        icon="flash-outline"
        nbTooltip="WebRTC"
        (click)="isUsingWebRtc$.next(false)"
      ></nb-action>
      <nb-action
        *ngIf="(isLiveStream$ | async) && (isUsingWebRtc$ | async) === false"
        icon="flash-off-outline"
        nbTooltip="HLS"
        (click)="isUsingWebRtc$.next(true)"
      ></nb-action>
      <nb-action
        icon="refresh-outline"
        nbTooltip="Refresh"
        (click)="refreshButtonClicked.next()"
      ></nb-action>
      <nb-action
        *ngIf="isAdminOrOwner$ | async"
        icon="settings-outline"
        nbTooltip="Edit"
        (click)="editButtonClicked.next()"
      ></nb-action>
      <nb-action *ngIf="isAdminOrOwner$ | async">
        <nb-toggle
          nbTooltip="Start/Stop"
          [ngModel]="isMissionActive$ | async"
          (ngModelChange)="missionActiveInactiveClicked.next($event)"
        ></nb-toggle>
      </nb-action>
      <!-- <nb-action icon="trash-2-outline" (click)="removeStreamsClick$.next()"> </nb-action> -->
    </nb-actions>
  </nb-card-header>
  <nb-tabset
    fullWidth
    [hidden]="(isMissionActive$ | async) === false"
    (changeTab)="sourceTabClicked.next($event.index)"
  >
    <nb-tab
      *ngFor="let source of videoSources$ | async"
      [active]="(currentSource$ | async)?.title === source.title && (currentSource$ | async)?.device === source.device"
      [tabTitle]="source.title"
      [tabSubtitle]="source.type === 'mobile' ? source.device : undefined"
    >
    </nb-tab>
  </nb-tabset>
  <ng-content></ng-content>
</nb-card>
